import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import { pageActions } from '.';

export interface PageState {
  page: any;
}

export const INITIAL_STATE: PageState = {
  page: null,
};

const handleFetchPage = (state: PageState) => {
  state.page = null;
};

const handleFetchPageSuccess = (state: PageState, { payload }: ReduxAction<any>) => {
  state.page = payload;
};

const HANDLERS = {
  ...actionHandler(pageActions.fetchPage, handleFetchPage),
  ...actionHandler(pageActions.fetchPageSuccess, handleFetchPageSuccess),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
