import { createSelector } from '@reduxjs/toolkit';
import { prop, propEq, pipe, pathOr, find, path } from 'ramda';

import { GlobalState } from '../reducers';

export const selectPageDomain = (state: GlobalState) => state.page;

export const selectPage = createSelector(selectPageDomain, prop('page'));

const getFileName = (elementName: string) =>
  pipe(pathOr([], ['blocks', 0, 'elements']), find(propEq('name', elementName)), path(['value', 'file']));

export const selectRCatTxt = createSelector(selectPage, state => getFileName('rcat')(state));

export const selectCreditTxt = createSelector(selectPage, state => getFileName('credit')(state));
