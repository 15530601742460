import { all, call, takeEvery, takeLatest } from 'redux-saga/effects';
// @ts-ignore
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import { reportError } from '../../shared/utils/reportError';
import { rCatActions } from '.';
import { api } from '../../shared/services/api';

function* fetchRCatProject(action: any) {
  try {
    const { data } = yield api.get('/projects/2');

    yield call(resolvePromiseAction, action, data);
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

function* fetchRCatDataSource(action: any) {
  try {
    const { data } = yield api.get(`/datasources/${action.payload.id}/records?orient=records`);

    yield call(resolvePromiseAction, action, { name: action.payload.name, results: data.results });
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

export function* watchRCat() {
  yield all([
    takeLatest(rCatActions.fetchRCatProject, fetchRCatProject),
    takeEvery(rCatActions.fetchRCatDataSource, fetchRCatDataSource),
  ]);
}
