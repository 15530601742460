import { actionPromiseCreator } from '../helpers';

const createAction = actionPromiseCreator('CREDIT_GEOGRAPHY');

export const fetchDataSources = createAction<void>('FETCH_DATA_SOURCES');

export interface DataSourcePayload {
  id: number;
}

export const fetchDataSourceRecords = createAction<DataSourcePayload>('FETCH_DATA_SOURCE_RECORDS');
