/* eslint-disable import/no-dynamic-require */
import { fonts } from './theme';
import { Font } from './theme.constants';

function fontFace(
  name: string,
  src: string,
  extensions: Array<string>,
  fontWeight = 'normal',
  fontStyle = 'normal',
  formats = ['woff2', 'woff', 'truetype']
): string {
  const sources = extensions
    .map((ext, index) => `url(${require('../fonts/' + src + `.${ext}`)}) format("${formats[index]}")`)
    .join(',');

  return `
    @font-face{
        font-family: "${name}";
        src: ${sources};

        font-weight: ${fontWeight};
        font-style: ${fontStyle};
    }
  `;
}
/* eslint-enable import/no-dynamic-require */

const generateFontsString = (): string =>
  [
    fontFace(fonts[Font.FRANCOISONE] as string, 'Francois_One/FrancoisOne-Regular', ['ttf']),
    fontFace(fonts[Font.SFCOMPACTMEDIUM] as string, 'SFCompactText/SF-Compact-Text-Medium', ['woff', 'woff2', 'eot']),
    fontFace(fonts[Font.SFCOMPACTREGULAR] as string, 'SFCompactText/SF-Compact-Text-Regular', ['woff', 'woff2', 'eot']),
    fontFace(fonts[Font.SFCOMPACTHEAVY] as string, 'SFCompactText/SF-Compact-Text-Heavy', ['woff', 'woff2', 'eot']),
    fontFace(fonts[Font.SFCOMPACTBOLD] as string, 'SFCompactText/SF-Compact-Text-Bold', ['woff', 'woff2', 'eot']),
    fontFace(fonts[Font.SFCOMPACTSEMIBOLD] as string, 'SFCompactText/SF-Compact-Text-Semibold', [
      'woff',
      'woff2',
      'eot',
    ]),
    fontFace(fonts[Font.FONTELLO] as string, 'fontello/fontello', ['woff', 'woff2', 'eot', 'ttf']),
    fontFace(fonts[Font.SFUILIGHT] as string, 'SF-UI-Text/SF-UI-Text-Light', ['woff', 'woff2', 'eot']),
    fontFace(fonts[Font.SFUIBOLD] as string, 'SF-UI-Text/SF-UI-Text-Bold', ['woff', 'woff2', 'eot']),
    fontFace(fonts[Font.SFUIMEDIUM] as string, 'SF-UI-Text/SF-UI-Text-Medium', ['woff', 'woff2', 'eot']),
  ].join('\n');

export default (): void => {
  const style = document.createElement('style');
  style.innerHTML = generateFontsString();
  document.head.appendChild(style);
};
