import { all, put, takeLatest } from 'redux-saga/effects';

import { reportError } from '../../shared/utils/reportError';
import { pageActions } from '.';
import { api } from '../../shared/services/api';

function* fetchPage() {
  try {
    const { data: creditData } = yield api.get('https://api.schemacms.rhg.com/pages/5');
    const { data: rCatData } = yield api.get('https://api.schemacms.rhg.com/pages/23');
    const data = {
      ...creditData,
      blocks: [{ elements: [...creditData.blocks[0].elements, ...rCatData.blocks[0].elements] }],
    };

    yield put(pageActions.fetchPageSuccess(data));
  } catch (error) {
    reportError(error);
  }
}

export function* watchPage() {
  yield all([takeLatest(pageActions.fetchPage, fetchPage)]);
}
