import { combineReducers } from 'redux';

import { reducer as localesReducer, LocalesState } from './locales/locales.redux';
import { reducer as startupReducer, StartupState } from './startup/startup.redux';
import { reducer as rCatReducer, RCatState } from './rCat/rCat.redux';
import { reducer as creditGeographyReducer, CreditGeographyState } from './creditGeography/creditGeography.redux';
import { reducer as pageReducer, PageState } from './page/page.redux';
//<-- IMPORT MODULE REDUX -->

export type GlobalState = {
  locales: LocalesState;
  startup: StartupState;
  rCat: RCatState;
  creditGeography: CreditGeographyState;
  page: PageState;
  //<-- INJECT MODULE STATE TYPE -->
};

export default function createReducer() {
  return combineReducers({
    locales: localesReducer,
    startup: startupReducer,
    rCat: rCatReducer,
    creditGeography: creditGeographyReducer,
    page: pageReducer,
    //<-- INJECT MODULE REDUCER -->
  });
}
