import { useDispatch, useSelector } from 'react-redux';
import { pageActions, pageSelectors } from '../../../modules/page';

export const usePage = (): any[] => {
  const rCatTxtFile = useSelector(pageSelectors.selectRCatTxt);
  const creditTxtFile = useSelector(pageSelectors.selectCreditTxt);

  return [rCatTxtFile, creditTxtFile];
};

export const useFetchPage = (): (() =>
  | ({ payload: unknown; type: string } & {})
  | ({ payload: undefined; type: string } & {})) => {
  const dispatch = useDispatch();

  return () => dispatch(pageActions.fetchPage());
};
