export enum Color {
  WHITE,
  BLACK,
  BORDER,
  DISABLED,
  PRIMARY,
  SECONDARY,
  ERROR,
  NAVYBLUE,
  DARK,
  GRAY,
  LIGHTGRAY,
  MILKGRAY,
  BLUE,
  GRAYBLUE,
  SLIGHTLYGRAY,
  PALEGRAY,
  BLACKGRAY,
  DARKGRAYBLUE,
  ROLLINGSTONE,
  GRAYCHATEAU,
  SILVERCHALICE,
  WOODSMOKE,
  ATHENSGRAY,
  IRON,
  HITGRAY,
  ROLLINGSTONEDARK,
  GRANNYSMITH,
  REGENTGRAY,
  PORCELAIN,
  ENDEAVOUR,
  SHAKESPEARE,
  SUNGLO,
  WILDSAND,
  GEYSER,
  MANATEE,
  ALTO,
  DOWNY,
  GALLERY,
  BOULDER,
  SPINDLE,
  SILVER,
  MINESHAFT,
}

export enum Font {
  FRANCOISONE,
  SFCOMPACTMEDIUM,
  SFCOMPACTREGULAR,
  SFCOMPACTBOLD,
  SFCOMPACTSEMIBOLD,
  SFCOMPACTHEAVY,
  FONTELLO,
  SFUILIGHT,
  SFUIMEDIUM,
  SFUIBOLD,
}

export enum Border {
  REGULAR,
}

export enum Shadow {
  PRIMARY,
}

export enum Size {
  HEADER,
  CONTENT_HORIZONTAL_PADDING,
  CONTENT_VERTICAL_PADDING,
}

export enum ZIndex {
  CONTENT,
  HEADER,
  OVERLAY,
}
