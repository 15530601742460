import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import { creditGeographyActions } from '.';

export interface DataSourceRecord {
  name: string;
  year: number;
  id: number;
  Province: string;
  Loans: number;
  quarter: number;
}
export interface DataSource {
  name: string;
  id: number;
  meta: any;
}

export interface CreditGeographyState {
  dataSources: DataSource[];
  dataSourceRecords: DataSourceRecord[];
}

export const INITIAL_STATE: CreditGeographyState = {
  dataSources: [],
  dataSourceRecords: [],
};

const fetchDataSources = (state: CreditGeographyState, { payload }: ReduxAction<any>) => {
  state.dataSources = payload;
};

const fetchDataSourceRecords = (state: CreditGeographyState, { payload }: ReduxAction<any>) => {
  state.dataSourceRecords = payload;
};

const HANDLERS = {
  ...actionHandler(creditGeographyActions.fetchDataSources.resolved, fetchDataSources),
  ...actionHandler(creditGeographyActions.fetchDataSourceRecords.resolved, fetchDataSourceRecords),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
