import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import { rCatActions } from '.';

export interface DataSource {
  tags: any;
  id: number;
  meta: object;
}

export interface Project {
  data_sources: DataSource[];
}

export interface RCatState {
  project: Project;
  data: any;
}

export const INITIAL_STATE: RCatState = {
  project: {
    /* eslint-disable @typescript-eslint/camelcase */
    data_sources: [],
  },
  data: {},
};

const handleFetchRCatProject = (state: RCatState, { payload }: ReduxAction<any>) => {
  state.project = payload;
};

const handleFetchRCatDataSource = (state: RCatState, { payload }: ReduxAction<any>) => {
  state.data[payload.name] = payload.results;
};

const HANDLERS = {
  ...actionHandler(rCatActions.fetchRCatProject.resolved, handleFetchRCatProject),
  ...actionHandler(rCatActions.fetchRCatDataSource.resolved, handleFetchRCatDataSource),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
