import { css, DefaultTheme } from 'styled-components';
import { Breakpoint, responsiveValue } from './media';
import { Border, Color, Font, Shadow, Size, ZIndex } from './theme.constants';

type Producer<T> = (...args: any[]) => T;
export type ThemeMap<T extends string | number, V = any> = Record<T, V>;
export type ThemeMapDef<T extends string | number, V = any> = Record<T, V | Producer<V>>;

export const colors: ThemeMapDef<Color, string> = {
  [Color.WHITE]: '#ffffff',
  [Color.BLACK]: '#000000',
  [Color.BORDER]: '#80809B',
  [Color.DISABLED]: '#DDDDE0',
  [Color.PRIMARY]: '#42f272',
  [Color.SECONDARY]: '#2249f9',
  [Color.ERROR]: '#fa7265',
  [Color.NAVYBLUE]: '#0077af',
  [Color.DARK]: '#212529',
  [Color.ATHENSGRAY]: '#f7f8fa',
  [Color.GRAY]: '#808080',
  [Color.LIGHTGRAY]: '#8fa5b2',
  [Color.MILKGRAY]: '#c4c4c4',
  [Color.BLUE]: '#007bff',
  [Color.DARKGRAYBLUE]: '#2d3539',
  [Color.GRAYBLUE]: '#4c585f',
  [Color.SLIGHTLYGRAY]: '#e6e8e9',
  [Color.PALEGRAY]: '#87969e',
  [Color.BLACKGRAY]: '#595959',
  [Color.ROLLINGSTONE]: '#707980',
  [Color.GRAYCHATEAU]: '#A3ACB0',
  [Color.SILVERCHALICE]: '#A3ACB0',
  [Color.WOODSMOKE]: '#16181B',
  [Color.SHAKESPEARE]: '#62AAD6',
  [Color.SUNGLO]: '#E66869',
  [Color.IRON]: '#cdd3d8',
  [Color.HITGRAY]: '#9fabb1',
  [Color.ROLLINGSTONEDARK]: '#6c787e',
  [Color.GRANNYSMITH]: '#879c9e',
  [Color.REGENTGRAY]: '#86959e',
  [Color.PORCELAIN]: '#f6f8f9',
  [Color.ENDEAVOUR]: '#005cb5',
  [Color.WILDSAND]: '#F4F4F4',
  [Color.GEYSER]: '#e1e6e9',
  [Color.MANATEE]: '#87869e',
  [Color.ALTO]: '#d3d3d3',
  [Color.DOWNY]: '#7fbbd7',
  [Color.GALLERY]: '#eeeeee',
  [Color.BOULDER]: '#7b7b7b',
  [Color.SPINDLE]: '#B9DAE9',
  [Color.SILVER]: '#ccc',
  [Color.MINESHAFT]: '#333',
};

export const fonts: ThemeMapDef<Font, string> = {
  [Font.FRANCOISONE]: 'FrancoisOne',
  [Font.SFCOMPACTMEDIUM]: 'SFCompactTextMedium',
  [Font.SFCOMPACTREGULAR]: 'SFCompactTextRegular',
  [Font.SFCOMPACTHEAVY]: 'SFCompactTextHeavy',
  [Font.SFCOMPACTBOLD]: 'SFCompactTextBold',
  [Font.SFCOMPACTSEMIBOLD]: 'SFCompactTextSemibold',
  [Font.FONTELLO]: 'Fontello',
  [Font.SFUILIGHT]: 'SFUILight',
  [Font.SFUIBOLD]: 'SFUIBold',
  [Font.SFUIMEDIUM]: 'SFUIMedium',
};

export const borders: ThemeMapDef<Border, string> = {
  [Border.REGULAR]: `1px solid ${colors[Color.BORDER]}`,
};

export const shadows: ThemeMapDef<Shadow> = {
  [Shadow.PRIMARY]: css`
    box-shadow: 10px 10px 0 rgba(0, 0, 55, 0.1);
  `,
};

export const sizes: ThemeMapDef<Size, number> = {
  [Size.HEADER]: 80,
  [Size.CONTENT_HORIZONTAL_PADDING]: responsiveValue(16, { [Breakpoint.TABLET]: 24 }),
  [Size.CONTENT_VERTICAL_PADDING]: responsiveValue(8, { [Breakpoint.TABLET]: 16 }),
};

export const activeBreakpoint: (props: { theme: DefaultTheme }) => Breakpoint = responsiveValue(Breakpoint.MOBILE, {
  [Breakpoint.DESKTOP_FULL]: Breakpoint.DESKTOP_FULL,
  [Breakpoint.DESKTOP_WIDE]: Breakpoint.DESKTOP_WIDE,
  [Breakpoint.DESKTOP]: Breakpoint.DESKTOP,
  [Breakpoint.TABLET]: Breakpoint.TABLET,
});

export const zIndexes = {
  [ZIndex.CONTENT]: 0,
  [ZIndex.HEADER]: 5,
  [ZIndex.OVERLAY]: 10,
};

export const theme = {
  colors,
  fonts,
  borders,
  shadows,
  sizes,
  zIndexes,
  activeBreakpoint,
};
