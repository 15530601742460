import React from 'react';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { hot } from 'react-hot-loader/root';
import { useAuth0 } from '@auth0/auth0-react';

import { AppComponent as App } from './app.component';
import { DEFAULT_LOCALE, translationMessages } from '../i18n';
import { ROUTES } from './app.constants';
import { asyncComponent } from '../shared/utils/asyncComponent';
//<-- IMPORT ROUTE -->

const Home = asyncComponent(() => import('./home'), 'Home');
const RCat = asyncComponent(() => import('./rcat'), 'RCat');
const CreditGeography = asyncComponent(() => import('./creditGeography'), 'CreditGeography');
const NotFound = asyncComponent(() => import('./notFound'), 'NotFound');

const MatchedLanguageComponent = () => {
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated && pathname !== '/') {
    window.location.href = '/';

    return null;
  }

  return (
    <App>
      <Switch>
        <Route exact path={`${match.path}${ROUTES.home}`}>
          <Home />
        </Route>
        <Route exact path={`${match.path}${ROUTES.creditGeography}`}>
          <CreditGeography />
        </Route>
        <Route exact path={`${ROUTES.rCat}`}>
          <RCat />
        </Route>
        {/* <-- INJECT ROUTE --> */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </App>
  );
};

export default hot(() => {
  return (
    <Switch>
      <Route path="/">
        <MatchedLanguageComponent />
      </Route>

      <IntlProvider locale={DEFAULT_LOCALE} messages={translationMessages[DEFAULT_LOCALE]}>
        <Route>
          <NotFound />
        </Route>
      </IntlProvider>
    </Switch>
  );
});
