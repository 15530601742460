import { all, fork } from 'redux-saga/effects';

import { watchStartup } from './startup/startup.sagas';
import { watchRCat } from './rCat/rCat.sagas';
import { watchCreditGeography } from './creditGeography/creditGeography.sagas';
import { watchPage } from './page/page.sagas';
//<-- IMPORT MODULE SAGA -->

export default function* rootSaga() {
  yield all([
    fork(watchStartup),
    fork(watchRCat),
    fork(watchCreditGeography),
    fork(watchPage),
    //<-- INJECT MODULE SAGA -->
  ]);
}
