import { all, call, takeLatest } from 'redux-saga/effects';
// @ts-ignore
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import { reportError } from '../../shared/utils/reportError';
import { creditGeographyActions } from '.';
import { api } from '../../shared/services/api';
import { DataSourcePayload } from './creditGeography.actions';

function* fetchDataSources(action: any) {
  try {
    const { data } = yield api.get('/projects/3/datasources');
    yield call(resolvePromiseAction, action, data.results);
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

function* fetchDataSourceRecords(action: { payload: DataSourcePayload }) {
  try {
    const { data } = yield api.get(`/datasources/${action.payload.id}/records?orient=records`);
    yield call(resolvePromiseAction, action, data.results);
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

export function* watchCreditGeography() {
  yield all([
    takeLatest(creditGeographyActions.fetchDataSources, fetchDataSources),
    takeLatest(creditGeographyActions.fetchDataSourceRecords, fetchDataSourceRecords),
  ]);
}
