import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { GlobalState } from '../reducers';

export const selectRCatDomain = (state: GlobalState) => state.rCat;

export const selectRCatProject = createSelector(selectRCatDomain, prop('project'));
export const selectRCatDataSources = createSelector(selectRCatProject, prop('data_sources'));

export const selectRCatData = createSelector(selectRCatDomain, prop('data'));
